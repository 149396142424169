<template>
  <div>
    <Slider />
    <Description section="financial" />
    <Strengths :strengths="strengths" />
    <Slogan />
    <TestimonialsPage />
  </div>
</template>

<script>
export default {
    name: "Fintech",
       components: {
       Slider: () => import('./sections/sliders/financials.vue'),
       Description: () => import('./sections/description'),
       Strengths: () => import('./sections/strengths'),
       Slogan: () => import('./sections/slogan'),
       TestimonialsPage: () => import('../home/testimonials'),
    },
      computed: {
       strengths () {
         const data = [
                {
                    image: 'Pie_chart.png',
                    title: '24/7 Support',
                    text: 'We pride ourselves in the ability to provide round-the -clock support to all our customers with short SLAs of less than 5 minutes.',
                    color: '#d29f2a',
                },
                 {
                    image: 'Note.png',
                    title: 'Integration of Payment Experience',
                    text: 'Our platform has flexible APIs such that Zamupay customers can integrate their business with it to activate payments.',
                    color: '#ffffff',
                },
                {
                    image: 'Pie_chart.png',
                    title: 'Reliability',
                    text: 'Through optimization of our systems and well-designed processing gateways, we  ensure reliable and highly available systems to ensure service to all at all times. ',
                    color: '#d29f2a',
                },
             
               
                {
                    image: 'Note.png',
                    title: 'Advanced Fraud Detection',
                    text: 'We have a combination of automated fraud system monitoring tools that proactively flag high-risk activity, detect illegitimate transactional behavior online and provides alerts and analysis tools.',
                    color: '#ffffff',
                },
         ];
         return data;
       }

    },

}
</script>

<style>

</style>